<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                meigara {{perf}}
                <DataTable :value="meigaras" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows
                :lazy="true" :paginator="true" :rows="10" ref="dt" dataKey="id"
                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                selectionMode="single"
                v-model:filters="filters" @filter="onFilter($event)" filterDisplay="row">
                    <Column field="id" header="No" :sortable="true"></Column>
                    <Column field="jpx_date" header="JPX更新日" :sortable="true"></Column>
                    <Column field="meigara_cd" header="銘柄コード" filterField="meigara_cd" filterMatchMode="contains" ref="meigara_cd" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                        </template>
                    </Column>
                    <Column field="meigara_name" header="銘柄名" :sortable="true"></Column>
                    <Column field="market_name" header="市場" filterField="market_name" filterMatchMode="contains" ref="market_name" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
                        </template>
                    </Column>
                    <Column field="industry33_cd" header="33業種コード" :sortable="true"></Column>
                    <Column field="industry33_name" header="33業種区分" filterField="industry33_name" filterMatchMode="contains" ref="industry33_name" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
                        </template>
                    </Column>
                    <Column field="kibo_cd" header="規模コード" :sortable="true"></Column>               
                    <Column field="kibo_name" header="33業種区分" filterField="kibo_name" filterMatchMode="contains" ref="kibo_name" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
                        </template>
                    </Column> 
                    <Column field="l_date" header="新規銘柄上場日" :sortable="true"></Column>
                    <Column field="r_date" header="差分更新日" :sortable="true"></Column>
                    <Column field="r_desc" header="差分更新内容" :sortable="true"></Column>
                    <Column field="delisted" header="上場廃止区分" :sortable="true"></Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import MeigaraService from '@/service/MeigaraService';

export default {
    data() {
        return {
            loading: false,
            totalRecords: 0,
            meigaras: null,
            lazyParams: {},
            filters1: {},
            filters: {
                'meigara_cd': {value: '', matchMode: 'contains'},
                'market_name': {value: '', matchMode: 'contains'},
                'industry33_name': {value: '', matchMode: 'contains'},
                'kibo_name': {value: '', matchMode: 'contains'},
            },
            perf: null,
        }
    },
    meigaraService: null,
    created() {
        this.meigaraService = new MeigaraService();
    },
    mounted() {
        this.loading = true;

        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: null,
            sortOrder: null,
            filters: this.filters
        };

        this.loadLazyData();
    },
    methods: {
        loadLazyData() {
            this.loading = true;

            setTimeout(() => {
                this.meigaraService.getAll(
                    {dt_params: JSON.stringify( this.lazyParams )})
                        .then(data => {
                            this.meigaras = data.payload.data;
                            this.totalRecords = data.payload.total;
                            this.loading = false;
                            this.perf = data.perf;
                    }
                );
            }, Math.random() * 1000 + 250);
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.loadLazyData();
        }
    }
}
</script>

<style scoped>

</style>